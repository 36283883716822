
import { defineComponent, ref, computed, onMounted, watchEffect } from "vue";
import EventCard from "@/components/EventCard.vue"; // @ is an alias to /src
import EventService from "@/services/EventService";
import { onBeforeRouteUpdate, useRouter } from "vue-router";

export default defineComponent({
  name: "EventList",
  components: {
    EventCard,
  },
  props: {
    page: {
      default: 1,
      type: Number,
    },
  },
  setup(props) {
    const router = useRouter();
    const events = ref(null);
    const totalEvents = ref(0);

    const hasNextPAge = computed(() => {
      // Find the total number of pages
      let totalPages = Math.ceil(totalEvents.value) / 2;
      // If this page is not the las page
      return props.page < totalPages;
    });

    onMounted(() => {
      // When reactive objects that are accessed inside this function change, run this function again
      watchEffect(() => {
        // Clear out the events on the page, so our user knows the API has been called
        events.value = null;
        return EventService.getEvents(2, props.page)
          .then((response) => {
            events.value = response.data;
            totalEvents.value = parseInt(response.headers["x-total-count"]);
          })
          .catch(() => {
            router.push({ name: "NetworkError" });
          });
      });
    });

    return {
      events,
      hasNextPAge,
    };
  },
});
